/* One line block
************************************************/

#countdown_clock.one_line_format {
  margin-top: 0;
  position: relative;
  width: auto;
  display: block;
  height: 31px;
}

#countdown_clock.one_line_format div {
  display: inline;
  line-height: 29px;
}

#countdown_clock.one_line_format .timetext {
  letter-spacing: 2px;
  font-family: 'Brandon Text Bold';
  /* font-size: 11px; */
}
/* Horizontal block
************************************************/

#countdown_clock.horizontal_block {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

#countdown_clock.horizontal_block #watch div > span {
  display: inline-block;
}

#countdown_clock.horizontal_block #watch > div {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

#countdown_clock.horizontal_block .timetext,
#countdown_clock #coming_soon {
  letter-spacing: 2px;
  font-family: 'Brandon Text Bold';
}
/* Horizontal with Animation Background
************************************************/

#countdown_clock.animation_block {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  /* display: flex; */
  flex-direction: column;
}

#countdown_clock.animation_block #watch div > span {
  display: inline-block;
}

#countdown_clock.animation_block #watch > div {
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

#countdown_clock.animation_block .timetext,
#countdown_clock #coming_soon {
  letter-spacing: 2px;
  font-family: 'Brandon Text Bold';
}

#countdown_clock.animation_block img#bg_animation {
  position: relative;
  top: 0px;
  left: 0px;
  height: auto;
  z-index: 0;
}

#countdown_clock.animation_block div#counter_block {
  position: absolute;
  top: 30px;
  text-align: center;
  width: 100%;
  z-index: 1;
}
